import React from 'react';
import moment from 'moment';

import GenericTitle from '../../../../../components/GenericSection/components/GenericTitle';
import AuditIcon from '../../../../../assets/icons/audit.svg';
import StyledAuditTrail from './styles';
import AuditStep from '../AuditStep';
import { IAuditTrail, IAudit } from '../../ProductCatalogue';

const AuditTrail: React.FC<{
  auditTrail: IAuditTrail[];
}> = ({ auditTrail }) => (
  <StyledAuditTrail>
    <GenericTitle img={AuditIcon} altText='Audit' title='Audit trail' />

    {auditTrail.map((trailStep: IAuditTrail) => (
      <div key={trailStep.date}>
        <div className='trail-column'>
          <p className='trail-column__timestamp trail-column__timestamp--regular'>
            {moment(trailStep.date).format('ll')}
          </p>
          <div className='bar' />
        </div>
        {trailStep.audit.map((a: IAudit) => (
          <AuditStep audit={a} key={a.filename} />
        ))}
      </div>
    ))}
  </StyledAuditTrail>
);

export default AuditTrail;
